import axios from '@axios'
import { getCodeLabel } from '@core/utils/filter'
import { getUserData } from '@/auth/utils'

const user = getUserData()
const userWarehouseIds = getCodeLabel('user_warehouse', user.user_id)
export default {
  namespaced: true,
  state: {
    condition:{
      dutyName : undefined,
      creatorName:undefined,
      fromwarehousename : undefined,
      towarehousename : undefined,
      outbound_begin_time:"",
      receipt_begin_time:"",
      create_begin_time:"",
      begin_time:"",
    },
    isFrom: true,
    isTo: true,
    isWarehouseStaff:user.role_id === 9 || user.role_id === 10 || user.role_id === 21,
    isExpressStaff:user.role_id === 17 || user.role_id === 18,
    isSuper:user.role_id === 14,
    isBusinessUser: user.role_id===5 || user.role_id===6 ||  user.role_id === 14
  },
  getters: {
    getIsFrom(state){
      return state.isFrom
    },
    getIsTo(state){
      return state.isTo
    },
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateState(state, payload) {
      if (user.role_id === 9) {
        state.isFrom = userWarehouseIds.includes(payload.from || -1)
        state.isTo = userWarehouseIds.includes(payload.to || -1)
      }
    },
    resetCondition(state){
      for (let conditionKey in state.condition) {
        state.condition[conditionKey] = undefined
      }
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/allotoutbound/search', { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/allotoutbound/view', { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/allotoutbound/edit', { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/allotoutbound/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/allotoutbound/state', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeIsBusiness(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/allotoutbound/changeIsBusiness', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    submitWareHouse(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/allotoutbound/submitWareHouse', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    warehouse(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/allotoutbound/warehouselist', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrderItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/allotoutbounditem/updateOrderItem', { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateWhInfo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/allotoutbound/updateWhInfo', { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateChInfo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/allotoutbound/updateChInfo', { params: params })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    rejectAllotOutboundData(ctx, params) {
      return new Promise( (resolve, reject) => {
        axios
            .post('/api/allotoutbound/rejectAllotOutboundData', params )
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    changeExpressStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/allotoutbound/changeExpressStatus', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    changeIsFinanceStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/allotoutbound/changeIsFinanceStatus', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    changeIsFinanceStatusAll(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/allotoutbound/changeIsFinanceStatusAll', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    updateModel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/allotoutbound/updateModel', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    updateModelReceipt(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/allotoutbound/updateModelReceipt', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    toPDF(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/allotoutbound/toPDF', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    exportToExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios({
              url: '/api/exportexcelfile/albExportToExcel',
              method:'get',
              params: params,
              responseType: 'blob',
            }
        )
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getProductQtyFromOneWarehouse(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/allotoutbound/getProductQtyFromOneWarehouse', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    isExitZeroCostStock(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/allotoutbound/isExitZeroCostStock', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


  },
}
