<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
<!--              需要修改方法-->
<!--              <b-button
                  variant="primary"
                  @click="outboundAll()"
                  style="margin: 5px"
              >
                <span class="text-nowrap">批量出库</span>
              </b-button>-->

<!-- :to="{ name: 'apps-allotoutbound-warehouse'}"
-->

              <b-button
                  variant="primary"
                  :to="{ name: 'apps-allotoutbound-warehouse'}"
              >
                <span class="text-nowrap">返回</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="warehouselist"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :order-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :order-desc.sync="isSortDirDesc"
          v-model="tableArray"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <!--          <b-link-->
          <!--              :to="{ name: 'apps-allotoutbounditem-edit', query: { id: data.item.id } }"-->
          <!--              class="font-weight-bold d-block text-nowrap"-->
          <!--          >-->
          <!--          </b-link>-->
          #{{ data.item.id }}
        </template>

        <template #cell(name)="data">
          {{ data.item.name }}{{ isGift(data.item.is_gift) }}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toTime(data.item.production_date)+
              '<br>'+'结束：'+toTime(data.item.expiry_date)+
              '</div>'"
              variant="success"
          />
        </template>

        <template #cell(batch_no)="data">
          批次：{{ data.item.batch_no }}
          <br>
          开始：{{ toTime(data.item.production_date) }}
          <br>
          结束：{{ toTime(data.item.expiry_date) }}
        </template>

        <template #cell(warestatus)="data">
          <span
              :class="'text-'+getCode('stock_status', data.item.warestatus).color">{{ getCodeLabel("stock_status", data.item.warestatus) }}</span>
        </template>

        <template #cell(qty)="data">
          {{ data.item.qty }}
        </template>

        <template #cell(allotbound_time)="data">
          {{ toTime(data.item.allotbound_time) }}
        </template>

        <template #cell(receipt_time)="data">
          {{ toTime(data.item.receipt_time) }}
        </template>

        <template #cell(allotbound_creator)="data">
          {{ getCodeLabel('user', data.item.allotbound_creator) }}
        </template>

        <template #cell(receipt_creator)="data">
          {{ getCodeLabel('user', data.item.receipt_creator) }}
        </template>

<!--        <template v-slot:head(select)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>

        <template v-slot:cell(select)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>-->


        <template #cell(trueqty)="data">
            {{ data.item.trueqty }}
        </template>

        <template #cell(is_receive)="data">
          <div v-if="data.item.is_receive == 1">
            对方实收数量：{{ data.item.receiveqty }}
            <br>
            退货(良品)：{{ data.item.returnqty_good }}
            <br>
            退货(不良品)：{{ data.item.returnqty_bad }}
          </div>
          <div v-else>
            对方尚未接收
          </div>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <div v-if="data.item.status == 0">
              <b-dropdown-item v-if="data.item.status == 0 && isFrom &&data.item.status ==999" @click="updateitem(data.item)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">完成出库</span>
              </b-dropdown-item>
              <b-dropdown-item v-else>
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">无菜单</span>
              </b-dropdown-item>
            </div>
            <div v-if="data.item.status != 0">
              <!--              <b-dropdown-item v-if="data.item.is_receive == 0" @click="showReveive(data.item)">-->
              <!--                <feather-icon icon="EditIcon" />-->
              <!--                <span class="align-middle ml-50">录入回执单</span>-->
              <!--              </b-dropdown-item>-->
              <b-dropdown-item v-if="data.item.is_receive == 0 && isTo" @click="showReveiveBack(data.item)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">回执单(无退仓)</span>
              </b-dropdown-item>
              <b-dropdown-item v-else>
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">无菜单</span>
              </b-dropdown-item>
            </div>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>


    </b-card>

    <b-modal
        id="receiveModal"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        @ok="checksubmit"
        :title="'调拨回执单'"
    >
      <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label="计划调拨数量"
          label-for="input-default"
      >
        <b-form-input
            id="qty"
            size="sm"
            v-model="qty"
            readonly=""
        />
      </b-form-group>
      <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label="实际调拨数量"
          label-for="input-default"
      >
        <b-form-input
            id="trueqty"
            size="sm"
            v-model="trueqty"
            readonly=""
        />
      </b-form-group>
      <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label="对方实收数量"
          label-for="input-default"
      >
        <b-form-input
            id="receiveqty"
            size="sm"
            v-model="receiveqty"
            type="number"
            @change="checkReceive()"
        />
      </b-form-group>
      <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label="对方退货数量(良品)"
          label-for="input-default"
      >
        <b-form-input
            id="returnqty_good"
            size="sm"
            type="number"
            v-model="returnqty_good"
            @change="checkreturn()"
        />
      </b-form-group>
      <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label="对方退货数量(不良品)"
          label-for="input-default"
      >
        <b-form-input
            id="returnqty_bad"
            size="sm"
            type="number"
            v-model="returnqty_bad"
            @change="checkreturn()"
        />
      </b-form-group>

    </b-modal>

    <b-modal
        id="receiveBackModal"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        @ok="checksubmit"
        :title="'调拨回执单'"
        hide-footer
    >
      <b-col md="12">
        <b-row>
          <b-col md="6">
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="计划调拨数量"
                label-for="input-default"
            >
              <b-form-input
                  size="sm"
                  v-model="qty"
                  readonly=""
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label="实际调拨数量"
                label-for="input-default"
            >
              <b-form-input
                  size="sm"
                  v-model="trueqty"
                  readonly=""
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12">
        <div class="inner-card-title">数量</div>
      </b-col>
      <AllotoutboundItemForm :warehouseId="parseInt(towarehouse_id)" :condition="condition" v-on:table="fromChildren">

      </AllotoutboundItemForm>

    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BIcon, BootstrapVueIcons,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, toRefs} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import allotoutbounditemUseList from './allotoutbounditemUseList'
import allotoutbounditemStore from './allotoutbounditemStore'
import {useToast} from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import {getUserData} from "@/auth/utils";
import {useRouter} from "@core/utils/utils";
import AllotoutboundItemForm from "@/views/apps/allotoutbounditem/AllotoutboundItemForm";
import { mapGetters, mapState } from 'vuex'
import allotoutboundStore from '@/views/apps/allotoutbound/allotoutboundStore'
import axios from "@/libs/axios";

export default {
  components: {
    AllotoutboundItemForm,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      allchecked:false,
      indeterminate: false,
      isCheckedObj: {},
      //checked: [],
      outbounditem_id: 0,
      qty: 0,
      trueqty: 0,
      receiveqty: 0,
      returnqty_good: 0,
      returnqty_bad: 0,
      outbound_id: 0,
      towarehouse_id: 0,
      condition: {},
      tableArray: [],
    }
  },
  // props:{
  //   outbound_id :{
  //     type : Number,
  //     default : 0
  //   }
  // },
  computed: {
    ...mapGetters('allotoutbound',{
      isFrom:'getIsFrom',
      isTo:'getIsTo',
    })
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('allotoutbounditem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    toggleAll(checked) {
      this.checked = []
      this.tableArray.forEach(element => {
        if (checked&&element.status==0) {
          this.checked.push(element);
          this.isCheckedObj['index' + element.id] = checked;
        }
        if(checked==false){
          this.isCheckedObj['index' + element.id] = checked;
        }

      });
      this.$forceUpdate()
    },
    checkboxClick(id) {
      if(this.checked == undefined){
        this.checked = []
      }
      let index = this.checked.indexOf(id);
      if (index > -1) {
        this.checked.splice(index, 1);
      } else {
        this.checked.push(id);
      }
      this.$forceUpdate()
    },
  },
  mounted() {
    this.isCheckedObj = {};
    this.tableArray.forEach(element => {
      this.$set(this.isCheckedObj, 'index' + element.id, false)
      //this.$set(this.isCheckedObj, element , false)
    });
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        if (newVal.length == 0) {
          this.indeterminate = false
          this.allchecked = false
        } else if (newVal.length == this.tableArray.length) {
          this.indeterminate = false
          this.allchecked = true
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    }
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('allotoutbounditem')) store.registerModule('allotoutbounditem', allotoutbounditemStore)
    if (!store.hasModule('allotoutbound')) store.registerModule('allotoutbound', allotoutboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutbounditem')) store.unregisterModule('allotoutbounditem')
      if (store.hasModule('allotoutbound')) store.unregisterModule('allotoutbound')

    })


    const router = useRouter()


    const {
      warehouselist,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = allotoutbounditemUseList({
      outbound_id: router.route.value.query.outbound_id,
    })

   /* const checkQty = function (item) {
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(item.trueqty)) {
        toast.error('实际调拨数量必须是正整数!')
        item.trueqty = 0
      }else{
        if(item.trueqty>item.qty){
          toast.error('实际调拨库数量不能大于计划调拨数量!!')
          item.trueqty=0
        }
      }

    }*/

    const updateitem = function (item) {


      if (item.trueqty === "" || item.trueqty < 0) {
        toast.error("实际出库数量错误")
        return false
      }
      if (item.trueqty < item.qty) {
        if (!confirm('实际出库数量小于计划出库数量，确定执行此操作吗？')) {
          return false
        }
      }
      if (confirm('该操作只能执行一次，确定操作吗？')) {
        axios
            .post('/api/allotoutbounditem/outbound', {itemid: item.outbounditem_id, trueqty: item.trueqty})
            .then(res => {
              if (res.data.code == 0) {
                toast.success("入库成功")
                refetchData()
              }else {
                toast.error(res.data.data)
              }
            })
      }
    }

    const showReveive = function (item) {
      this.$bvModal.show('receiveModal')
      this.outbounditem_id = item.outbounditem_id
      this.qty = item.qty
      this.trueqty = item.trueqty
      this.receiveqty = item.receiveqty
      this.returnqty_good = item.returnqty_good
      this.returnqty_bad = item.returnqty_bad
    }

    const showReveiveBack = function (item) {
      this.$bvModal.show('receiveBackModal')
      this.outbounditem_id = item.outbounditem_id
      this.qty = item.qty
      this.trueqty = item.trueqty
      this.condition = item
    }


    const checkReceive = function () {
      if (this.receiveqty > this.qty) {
        this.receiveqty = 0
      }
    }

    const checkreturn = function () {
      if (parseInt(this.returnqty_good) + parseInt(this.returnqty_bad) + parseInt(this.receiveqty) > parseInt(this.trueqty)) {
        this.returnqty_good = 0
        this.returnqty_bad = 0
      }
    }

    const checksubmit = function () {
      if (parseInt(this.returnqty_good) + parseInt(this.returnqty_bad) + parseInt(this.receiveqty) != parseInt(this.trueqty)) {
        toast.error("对方退货数量(不良品) + 对方退货数量(良品) + 对方实收数量 不等于 实际出库数量，请重新填写")
        return false
      }
      if (this.receiveqty == 0) {
        if (!confirm("对方实收数量为0，确认此操作？")) {
          return false
        }
      }
      if (confirm("此操作只能执行一次，确认此操作？")) {
        axios
            .get('/api/allotoutbounditem/receiveitem', {
              itemid: this.outbounditem_id,
              receiveqty: this.receiveqty,
              receiveqtylocationid: 23,
              returnqtygood: this.returnqty_good,
              returnqtygoodlocationid: 23,
              returnqtybad: this.returnqty_bad,
              returnqtybadlocationid: 23,
            })
            .then(res => {
              if (res.data.code == 0) {
                toast.success("操作成功")
                refetchData()
              } else {
                toast.error(res.data.data)
              }
            })
      }
    }

    const isGift = function (params) {
      if (params === 1) {
        return "(赠)"
      } else {
        return null
      }
    }

    const fromChildren = function (val) {
      if (val === "success") {
        this.$bvModal.hide('receiveBackModal')
        refetchData()
      }
      // console.log("这是从子页面传来的", this.salesOrderItem)
    }

    const outboundAll = function (){

      if(this.checked==undefined||this.checked.length==0){
            toast.error("最少选择一个才可以出库！")
            return false
          }
      var  ResultTrue =false
      var  ResultQty ="相关信息描述："
      this.checked.forEach(item => {
        if (item.trueqty === "" || item.trueqty < 0) {
          ResultTrue =true
          ResultQty = ResultQty+ item.id +":实际出库数量错误;"
        }
        if (item.trueqty < item.qty) {
          ResultQty = "相关信息:存在实际调拨数量小于计划调拨数量，确定出库！"
        }

        })

      if(ResultTrue==true){
        toast.error(ResultQty)
        return false
      }else{
        if (confirm('该操作只能执行一次，确定操作吗？')) {
          if (!confirm(ResultQty)) {
            return false
          }else{
            this.checked.forEach(item => {
              axios
                  .post('/api/allotoutbounditem/outbound', {itemid: item.outbounditem_id, trueqty: item.trueqty})
                  .then(res => {
                    if (res.data.code == 0) {
                      toast.success("入库成功")
                      refetchData()
                    } else {
                      toast.error(res.data.data)
                    }
                  })
            })
          }
        }
        this.checked.forEach(element => {
          this.isCheckedObj['index' + element.id] = false;
        });
        this.checked.length = 0
        this.indeterminate = false
        this.allchecked = false
      }


    }



    return {

      warehouselist,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,

      //checkQty,
      updateitem,
      showReveive,
      checkReceive,
      checkreturn,
      checksubmit,
      isGift,
      showReveiveBack,
      fromChildren,
      outboundAll,


    }
  },
  created() {
    this.outbound_id = this.$route.query.outbound_id || 0
    this.towarehouse_id = this.$route.query.towarehouse_id || 0
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
