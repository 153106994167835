<template>
  <div>
    <ValidationObserver ref="observer">
    <b-table-simple hover small caption-top responsive>

      <b-thead v-show="items.length > 0">

        <b-tr>
          <b-th>序号</b-th>
          <b-th>仓位</b-th>
          <b-th>数量</b-th>
          <b-th>是否良品</b-th>
          <b-th>操作</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>

        <b-tr v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
        >
            <b-td>
              <span>{{ index + 1 }}</span>
            </b-td>
            <b-td>
              <ValidationProvider rules="required" name="仓位" #default="{ errors }" :vid="`location_${index}`">
                <b-form-input
                    id="location"
                    :value="getCodeLabel('warehouse_location', item.location_fullname)"
                    @click="showModal(index)"
                    readonly
                    placeholder="点击搜索仓位"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-td>
            <b-td>
              <ValidationProvider rules="required|nonnegativeInteger" name="数量" #default="{ errors }" :vid="`qty_${index}`">
                <b-form-input
                    id="qty"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                    v-model="item.qty"
                    @change="checkData(item,index)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-td>
          <b-td>
            <b-form-checkbox
                name="check-button"
                switch
                value="0"
                unchecked-value="1"
                :id="'status'+index"
                v-model="item.status"
            ></b-form-checkbox>
          </b-td>
            <b-td>
              <b-button
                  variant="outline-danger"
                  @click="removeItem(index)"
              >
                <feather-icon
                    icon="XIcon"
                    class="mr-25"
                />
                <span>删除</span>
              </b-button>
            </b-td>

        </b-tr>
      </b-tbody>
      <b-tfoot>

      </b-tfoot>
    </b-table-simple>
    </ValidationObserver>
    <b-col cols="12"
           class="mt-50"
    >
      <b-button
          variant="primary"
          class="mr-1"
          @click="repeateAgain"
      >
        <feather-icon
            icon="PlusIcon"
            class="mr-25"
        />
        <span>增加一行</span>
      </b-button>
      <b-button
          variant="primary"
          @click="save"
          v-show="items.length > 0"
          v-if="saveShow"
      >
        <feather-icon
            icon="SaveIcon"
            class="mr-25"
        />
        <span>保存</span>
      </b-button>
    </b-col>
    <b-modal
        id="modal-select-location"
        ok-only
        ok-title="确认"
        @ok="onSelectLocation"
        cancel-title="取消"
        centered
        size="lg"
        title="选择我方仓库"
        ref="locationModal"
    >
      <warehouse-location-list
          ref="locationSelect" :warehouseId="warehouseId"
      >
      </warehouse-location-list>
    </b-modal>

  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import warehouselocationStore from '@/views/apps/warehouselocation/warehouselocationStore'
import { getCode, getCodeOptions,getCodeLabel } from '@core/utils/filter'
import { addDate } from '@core/utils/utils'
import WarehouseLocationList from '@/views/apps/warehouselocation/modal/WarehouseLocationList'
import allotoutbounditemStore from "@/views/apps/allotoutbounditem/allotoutbounditemStore";

export default {
  components: {
    WarehouseLocationList,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    flatPickr,
  },
  data() {
    return {
      items: [{ status: 0 }],
      selectedIndex: {},
      status: {},
      saveShow:true,
    }
  },
  props: {
    condition: {
      type: Object,
      default: null,
    },
    warehouseId: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    repeateAgain() {
      this.items.push({ status: 0 })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    onSelectLocation() {
      let item = this.items[this.selectedIndex]

      let res = this.$refs.locationSelect.selected[0]
      if (res) {
        item.location_fullname = res.location_fullname
        item.location_id = res.id
        this.items.splice(this.selectedIndex, 1, item)
      }
    },
    showModal(index) {
      this.$refs['locationModal'].show()
      this.selectedIndex = index
    }
  },
  setup(props) {
    // Register module
    if (!store.hasModule('allotoutbounditem')) store.registerModule('allotoutbounditem', allotoutbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutbounditem')) store.unregisterModule('allotoutbounditem')
    })
    const toast = useToast()
    let condition = props.condition
    const checkData = function (item, index) {

      let qty = parseInt(item.qty)
      let maxQty = condition.qty
      let trueQty = parseInt(condition.trueqty)
      //可输入的数量限制
      let qtyLimit = trueQty - this.items.filter((item, i) => i !== index)
          .reduce(
              (acc, cur) => acc + parseInt(cur.qty)
              , qty
          )
      if (qtyLimit<0){
        toast.error('超过实际调拨数量')
        item.qty = 0
      }
      // if (qty > trueQty) {
      //   toast.error('超过最大数量')
      //   item.qty = qtyLimit
      // }
    }
    const save = async function () {
      let item = props.condition
      if (this.items.length !== 0) {
        let items = this.items
        let success = await this.$refs.observer.validate()
        if (success) {
          this.saveShow=true
          let receiveqty = []
          let receivestatus = []
          let receivelocationid = []

          items.forEach(item => {
            receiveqty.push(item['qty'])
            receivestatus.push(item['status'])
            receivelocationid.push(item['location_id'])
          })

          store.dispatch('allotoutbounditem/receiveitemnoreturn', {
            itemid: item.outbounditem_id,
            receiveqty: receiveqty.join(","),
            receivestatus: receivestatus.join(","),
            receivelocationid: receivelocationid.join(","),
          })
              .then(res => {
                if (res.data.code === 0) {
                  toast.success('数据已保存!')
                  this.$emit("table","success")
                  this.saveShow = true
                } else {
                  toast.error(res.data.data)
                }
              })
        } else {
          toast.error('请填写缺失字段')
        }
      } else {
        toast.error('请先进行入库数据填写')
      }
    }
    return {
      checkData,
      // getLocation,
      getCodeOptions,
      getCodeLabel,
      save,
      addDate
    }
  },

}
</script>

<style lang="scss">
.repeater-form {
  display: flex;
  overflow: hidden;
  transition: .35s height;
  flex-direction: row
}
</style>
